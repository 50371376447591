import React from "react";
import "./victim-support-page.css";
import VictimSupportImage from "../../resources/victim-support.jpeg";

class VictimSupportPage extends React.Component {
  render() {
    return (
      <section className="victim-support-page-section" id="victim-support">
        <div className="container">
          <div className="row">
            <div className="image-overflow col-md-6">
              <img src={VictimSupportImage} alt="Lady of our Community" />
            </div>
            <div className="col-md-6 centered-text">
              <h1>VICTIM SUPPORT</h1>
              <br />
              <p>
                The Honeydew Community Policing Forum Victim Support Unit is the
                beating heart of Honeydew police.
              </p>
              <br />
              <p>
                The Honeydew CPF Victim Support is a voluntarily (non-religious)
                group designed to help victims within the community to address
                any issues that result from their exposure to crime which often
                results in various levels of trauma.
              </p>
              <p>
                The unit, which works in conjunction with the forum and police
                officers, has the specific role of being present at a crime
                scene to assist the victims with trauma and counseling. The role
                of the trained volunteers is to act as a buffer between the
                victims and the police.
              </p>
              <p>
                The committed volunteers of the Honeydew CPF Victim Support
                offers support to victims of crime 24 hours a day. All the
                counseling and support is done free of charge.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VictimSupportPage;
