import React from "react";
import ContactBanner from "../../resources/contact.jpeg";
import "./contact-us.css";
import SectorOne from "../../resources/sector 1.jpg";
import SectorTwo from "../../resources/sector 2.jpg";
import SectorThree from "../../resources/sector 3.jpg";

class ContactUsPage extends React.Component {
  render() {
    return (
      <section className="contact-us-page-section" id="contact-us">
        <img src={ContactBanner} alt="Contact Us Banner" />
        <div className="container">
          <div className="centered-text">
            <h1 class="large-text">
              <strong>CONTACT NUMBERS</strong>
            </h1>
            <p>
              <strong>
                In case of an emergency residents need to contact 10111 First
                and request for a IR (Incident Report) Number once the call has
                been logged, so that there is a record of them calling for
                assistance.
              </strong>
            </p>
            <p>
              Once that has been done, they can also follow up with Honeydew
              Police station on 011 801 8613/14/15 or alternatively contact the
              CSC on 082 337 1426.
            </p>
            <div className="row">
              <h1>Contact details for each sector is as follow:</h1>
              <p class="col-md-4">
                <strong>Sector 1 Manager</strong>
                <hr />
                WO Maphala
                <br />
                082 411 6796
                <br />
                <br />
                <strong>Sector 1 Vans</strong>
                <hr />
                071 675 7111
                <br />
                071 675 7101
              </p>
              <p class="col-md-4">
                <strong>Sector 2 Manager</strong>
                <hr />
                WO Makhura
                <br />
                071 675 7108
                <br />
                <br />
                <strong>Sector 2 Vans</strong>
                <hr />
                071 675 7112
                <br />
                071 675 7102
              </p>
              <p class="col-md-4">
                <strong>Sector 3 Manager</strong>
                <hr />
                Captain Jacobs
                <br />
                079 895 0769
                <br />
                <br />
                <strong>Sector 3 Vans</strong>
                <hr />
                071 675 7103
                <br />
                071 675 7107
              </p>
            </div>
            <br />
            <p>
              <i>
                Contact information for Honeydew CPF is as follows: Take note
                this is not to be used in case of emergency.
              </i>
            </p>
            <p>
              <i>Whatsapp Only (messages) Number 072 895 1289</i>
            </p>
            <br />
            <p>
              <strong>
                Below is also the Facebook Link which people can also use to get
                in contact with us.
                <br />
                <a href="https://www.facebook.com/HDCPF">
                  https://www.facebook.com/HDCPF
                </a>
              </strong>
            </p>
            <br />
            <div class="row">
              <h2>
                Honeydew is broken down in 3 sectors. See below each area
                covered.
              </h2>
              <br />
              <br />
              <br />
              <p class="col-md-4">
                <h3>SECTOR 1</h3>
                <hr />
                Northwold
                <br />
                Sundowner
                <br />
                Strydom Park
                <br />
                Sonneglans
                <br />
                Bromhof
                <br />
                Boskruin
                <br />
                Bush Hill AH
                <br />
                Kelly Ridge
                <br />
                Hunter’s Hill AH
                <br />
                Randpark Ridge
              </p>
              <p class="col-md-4">
                <h3>SECTOR 2</h3>
                <hr />
                Randpark Ridge
                <br />
                Weltevreden Park
                <br />
                Allen’s Nek
                <br />
                Constantia Kloof
                <br />
                Constantia Park
                <br />
                Hillfox
                <br />
                Panorama AH
              </p>
              <p class="col-md-4">
                <h3>SECTOR 3</h3>
                <hr />
                Struben’s Valley
                <br />
                Clearwater
                <br />
                Little Falls
                <br />
                Amorosa
                <br />
                Aanwins AH
                <br />
                Alsef AH
                <br />
                Ambot AH
                <br />
                Kimbult AH
                <br />
                Eagle Canyon
                <br />
                Fever Tree Estate
                <br />
                Harveston
                <br />
                Honeydew Ridge
                <br />
                Honeydew View
                <br />
                Honeydew Manor
                <br />
                Honeydew
                <br />
                Milky Way
                <br />
                Radiokop
                <br />
                Laser Park
                <br />
                Wilgeheuvel
                <br />
                Wilgespruit
                <br />
                Willowbrook
                <br />
                Ruimsig
                <br />
                Poortview
                <br />
                Tres Jolie
                <br />
                Zonnehoewe
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUsPage;
