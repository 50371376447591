import React from "react";

import CPFPatroller from "../../resources/cpf-member.jpeg";
import "./volunteer-patrollers-page.css";

class VolunteerPatrollersPage extends React.Component {
  render() {
    return (
      <section
        className="volunteer-patrollers-page-section"
        id="volunteer-patrollers"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>BECOME A PATROLLER</h1>
              <br />
              <h2>Working Together Towards a Safer Tomorrow</h2>
              <br />
              <p>
                In partnership with SAPS and other relevant role players, the
                patrollers provide a supportive initiative to be the eyes and
                ears for SAPS with the aim of crime prevention. As a patrol
                group it involves residents becoming more proactive and aware to
                the risk of crime and taking action to protect their own
                property and that of their neighbors.
              </p>
              <p>
                The CPF Patroller program is a voluntary service which is
                offered to residents within the Honeydew Precinct. We are
                looking for more patrollers so if you have at least 1 night a
                Month to offer up get in contact with Jon Rosenberg on 082 450
                2305 and join this incredible team.
              </p>
            </div>
            <div className="col-md-6">
              <img src={CPFPatroller} alt="Member of our Community" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VolunteerPatrollersPage;
