import React from "react";
import "./navbar.css";
import { NavDropdown, Navbar, Nav } from "react-bootstrap";
import { Facebook } from "react-bootstrap-icons";

class NavigationBar extends React.Component {
  render() {
    return (
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about/">About</Nav.Link>
            <NavDropdown
              title="Volunteer Programs"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/volunteer-patrollers/">
                Volunteer Patrollers
              </NavDropdown.Item>
              <NavDropdown.Item href="/victim-support/">
                Victim Support
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/feedback/">Give us your feedback</Nav.Link>
            <Nav.Link href="/contact-us/">Contact Us</Nav.Link>
            <Nav.Link href="/faq/">FAQ</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <a href="https://www.facebook.com/HDCPF/">
          <Facebook />
        </a>
      </Navbar>
    );
  }
}

export default NavigationBar;
