import logo from "./logo.svg";
import "./App.css";
import NavigationBar from "./navbar/navbar";
import FrontPage from "./pages/front-page/front-page";
import Footer from "./footer/footer";
import { Switch, Route } from "react-router";
import AboutPage from "./pages/about-page/about-page";
import VolunteerPatrollersPage from "./pages/volunteer-page/volunteer-patrollers-page";
import VictimSupportPage from "./pages/volunteer-page/victim-support-page";
import FeedbackPage from "./pages/feedback-page/feedback-page";
import ContactUsPage from "./pages/contact-us-page/contact-us-page";
import FAQPage from "./pages/faq-page/faq-page";

function App() {
  return (
    <div className="CPF">
      <NavigationBar />
      <Switch>
        <Route exact path="/" component={FrontPage} />
        <Route exact path="/about/" component={AboutPage} />
        <Route
          exact
          path="/volunteer-patrollers/"
          component={VolunteerPatrollersPage}
        />
        <Route exact path="/victim-support/" component={VictimSupportPage} />
        <Route exact path="/feedback/" component={FeedbackPage} />
        <Route exact path="/contact-us/" component={ContactUsPage} />
        <Route exact path="/faq/" component={FAQPage} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
