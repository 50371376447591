import React from "react";

class FAQPage extends React.Component {
  render() {
    return (
      <section className="faq-page-section" id="faq">
        <div className="container">
          <h1 className="centered-text">
            <strong>FREQUENTLY ASKED QUESTIONS</strong>
          </h1>
          <br />
          <br />
          <h2>
            <strong>WHAT IS A COMMUNITY POLICE FORUM (CPF)?</strong>
          </h2>
          <br />
          <p>
            A Community Police Forum is a group of people from different
            communities and police representatives who meet to discuss safety
            problems in their communities. They aim at ensuring police
            accountability, transparency, and effectiveness in the community.
            CPFs are established in terms of section 19(1) of the S.A.P. S. Act,
            Act 68 of 1995.
          </p>
          <br />
          <br />
          <h2>
            <strong>WHAT ARE THE OBJECTIVES OF COMMUNITY POLICING?</strong>
          </h2>
          <br />
          <p>
            Establishing a partnership between police and the communities they
            serve, ensuring effective protection of communities and a better
            quality of life.
          </p>
          <ul>
            <li>
              Ensuring the police address the primary needs of the community,
              and are accountable to these needs.
            </li>
            <li>
              Enhancing the quality of information available to the police to
              develop a proactive and problem-solving approach to crime and
              violence.
            </li>
            <li>
              Providing communities with a visible and accessible policing
              presence.
            </li>
            <li>
              Enhancing the public’s confidence in the police and deterring
              criminals.
            </li>
            <li>
              Aligning the values of the police organisation with those of
              democratic South Africa
            </li>
            <li>
              Inspiring police officers who can interact sensitively with their
              communities and in a manner that respects local values.
            </li>
          </ul>
          <br />
          <br />
          <h2>
            <strong>WHAT ARE THE FUNCTIONS OF CPFS?</strong>
          </h2>
          <br />
          <ul>
            <li>
              Promotion of accountability of the local police to the community,
              and cooperation of the community with the local police.
            </li>
            <li>
              Monitoring the effectiveness and efficiency of the police serving
              the community.
            </li>
            <li>Examination and advice on local policing priorities.</li>
            <li>
              Evaluation of the provision of services such as:
              <ul>
                <li>Distribution of resources</li>
                <li>The way complaints and charges are handled</li>
                <li>Patrolling of residential and business areas</li>
              </ul>
            </li>
            <li>
              Keeping records, writing reports and making recommendations to the
              Station Commissioners, the Provincial Commissioner and the MEC.
            </li>
            <li>
              The CPF will make questions available regarding local policing
              matters, and allow enquiries when necessary.
            </li>
          </ul>
          <br />
          <br />
          <h2>
            <strong>
              WHO SHOULD BE CONSULTED IN THE ESTABLISHMENT OF A CPF?
            </strong>
          </h2>
          <br />
          <p>
            Our country’s Constitution clearly stipulates that in order to
            establish a CPF, a Station Commissioner shall identify community
            based organisations and interested individuals, after consulting
            with the mayor or his/her representative of the local Municipality.
            This includes representatives from all facets within the community,
            i.e. religions groups, political groups, sports clubs, schools, and
            taxi associations. People who are not part of any group or
            organisation also have a right to be included.
          </p>
          <p>
            It is important that the police are well represented with the heads
            of the different divisions represented (Visible Policing, Crime
            Prevention, Detective, etc.) The Station Commissioner should always
            be available at CPF meetings.
          </p>
        </div>
      </section>
    );
  }
}

export default FAQPage;
