import React from "react";
import "./about-page.css";
import CPFvideo from "../../resources/CPF All About.mp4";
import videoPoster from "../../resources/video-poster.jpeg";

class AboutPage extends React.Component {
  render() {
    return (
      <section className="about-page-section" id="about">
        <div className="container">
          <video width="100%" height="100%" controls>
            <source
              src={CPFvideo}
              type="video/mp4"
              autoplay="false"
              poster={videoPoster}
            />
            Your browser does not support the video tag.
          </video>
          <div class="left-text">
            <h1 class="medium-text">
              <strong>WHAT IS THE CPF?</strong>
            </h1>
            <div>
              <p>
                Community Policing was created by the Police Act of 1995 and it
                is being used as a philosophy that guides police management
                styles and operational strategies and emphasises the
                establishment of police-community partnerships and a problem
                solving approach responsive to the needs of the community.
              </p>
              <p>
                It is based on the assumption that the objectives of the SAPS,
                namely the prevention, combating and investigation of crime; the
                maintenance of public order; the provision of protection and
                security to the community and their property; and upholding and
                enforcing the law, can only be achieved through the
                collaborative effort of the SAPS; other government institutions;
                the organisations and structures of civil society; and
                individual citizens.
              </p>
              <p>
                A major objective of community policing is to establish an
                active partnership between the Police and the community through
                which crime, service delivery and police-community relations can
                jointly be analysed and appropriate solutions designed and
                implemented. This however requires that the Police should
                consciously strive to create an atmosphere in which potential
                community partners are willing and able to co-operate with the
                Police.
              </p>
              <p>
                By fully engaging the entire community in this partnership, we
                will make a significant impact on the crime in Honeydew.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutPage;
