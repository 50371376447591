import React from "react";
import axios from "axios";
import "./feedback-page.css";
import FeedbackBanner from "../../resources/feedback-banner.jpeg";

const API_PATH = "/static/contact/contact-form.php";

class FeedbackPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      surname: "",
      email: "",
      number: "",
      message: "",
      mailSent: false,
      error: null,
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: result.data.sent,
        });
        // document.getElementsByName("submit").click(function () {
        //   document.ajax({
        //     url: `${API_PATH}`,
        //     success: function (result) {
        //       if (result === "sent") {
        //         alert("Message Sent!");
        //       }
        //     },
        //   });
        // });
      })
      .catch((error) => this.setState({ error: error.message }));
  };

  render() {
    return (
      <section className="feedback-page-section" id="feedback">
        <img src={FeedbackBanner} alt="Give us feedback" />
        <div className="container centered-text">
          <h1 className="feedback-title">GIVE US YOUR FEEDBACK</h1>
          <form method="POST" action="#" className="contact-form">
            <input
              type="text"
              name="nameZone"
              className="nameZone"
              placeholder="First Name"
              onChange={(e) => this.setState({ name: e.target.value })}
              value={this.state.name}
            />
            <hr />
            <input
              type="text"
              name="surnameZone"
              className="surnameZone"
              placeholder="Last Name"
              onChange={(e) => this.setState({ surname: e.target.value })}
              value={this.state.surname}
            />
            <hr />
            <input
              type="email"
              name="emailZone"
              className="emailZone"
              placeholder="Email"
              onChange={(e) => this.setState({ email: e.target.value })}
              value={this.state.email}
            />
            <hr />
            <input
              type="text"
              name="numberZone"
              className="numberZone"
              placeholder="Enter your phone number"
              onChange={(e) => this.setState({ number: e.target.value })}
              value={this.state.number}
            />
            <hr />
            <textarea
              name="messageZone"
              className="messageZone"
              placeholder="Please tell us about your experience or how we can improve?"
              onChange={(e) => this.setState({ message: e.target.value })}
              value={this.state.message}
            />
            <hr />
            <input
              name="submit"
              type="submit"
              className="btn"
              value="Send"
              onClick={(e) => this.handleFormSubmit(e)}
            />
            <br />
            <div>
              {this.state.mailSent && <div>Thank you for contcting us.</div>}
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default FeedbackPage;
