import React from "react";
import "./footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div>
          <p>©2021 by Honeydew Community Police.</p>
          <p>Created and Maintained by RuTech Solutions</p>
        </div>
      </div>
    );
  }
}

export default Footer;
