import React from "react";
import "./front-page.css";
import CPFlogo from "../../resources/CPF Logo New.jpg";

class FrontPage extends React.Component {
  render() {
    return (
      <section className="front-page-section" id="home">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1 class="medium-text">
                <strong>
                  Honeydew Community Policing Forum Mission Statement
                </strong>
              </h1>
              <br />
              <br />
              <h2>
                <i>"To make Honeydew a safer precinct for all."</i>
              </h2>
            </div>
            <div className="logo-div col-md-4">
              <img
                className="logo-image"
                src={CPFlogo}
                alt="Honeydew CPF Logo"
              />
            </div>
          </div>
          <div className="centered-text">
            <h1 className="large-text">
              WE ARE HONEYDEW COMMUNITY POLICE FORUM
            </h1>
            <h2 className="small-text">
              "To make Honeydew a safer precinct for all."
            </h2>
            <div className="container">
              <p>
                This site is here to make you aware of crime trends in Honeydew,
                enable you to communicate with the CPF and the Honeydew Police
                Service, keep you abreast of goings on in the community and
                provide resident associations a platform to communicate with
                their residents.
              </p>
              <p>
                Honeydew is known far and wide for the good work done by the CPF
                and the present committee intend to build on this reputation and
                work towards making Honeydew a safer area for all residing in
                it.
              </p>
              <p>
                <strong>
                  We can only succeed if we have the help and support of all the
                  residents in Honeydew.
                </strong>
              </p>
            </div>
            <a href="/about/">
              <div className="learn-more-button">Learn More</div>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default FrontPage;
